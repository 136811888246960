
import { defineComponent, onMounted, ref } from 'vue'
import axios from '@/http'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'

export default defineComponent({
	name: 'SearchProduct',

	setup() {
		const modelInput = ref<string>()
		const searchInputRef = ref<HTMLInputElement>()
		const router = useRouter()

		onMounted(() => {
			searchInputRef.value?.focus()
		})

		async function search() {
			try {
				const product = await axios.get('/product/find-by-id-variation/' + modelInput.value)

				router.push('/product/' + product.data.id)
			} catch (e) {
				modelInput.value = ''
				ElMessage.error('Продукт не найден')
			}
		}

		return { modelInput, searchInputRef, search }
	}
})
